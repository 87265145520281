import React, { Fragment, useMemo } from "react";
import { SystemProps, Box, Stack } from "@storyofams/react-ui";
import { createClient } from "@supabase/supabase-js";
import { useRouter } from "next/router";
import { useQuery } from "react-query";
import { Divider } from "../../../../components";
import { BlogCard } from "../../../../components/common/BlogCard";
import { TitleCombo } from "../molecules/TitleCombo";

type BlogBarProps = {
  first?: boolean;
  block?: boolean;
  content: {
    title: string;
    anchor_id?: string;
    description?: string;
    link_label?: string;
    link_url?: any;
    blog_list: any;
    recipes_ids?: any;
  };
} & SystemProps;

export const BlogBar = ({ content, first, ...props }: BlogBarProps) => {
  const supabase = createClient(
    process.env.NEXT_PUBLIC_SUPABASE_URL,
    process.env.NEXT_PUBLIC_SUPABASE_KEY
  );
  const getRecipeItems = async (): Promise<any> => {
    const ids =
      typeof content.recipes_ids[0] === "number"
        ? content.recipes_ids.map((id) => Number(id))
        : content.recipes_ids.map(({ id }) => Number(id));
    try {
      //@ts-ignore
      let query = supabase
        .from("recipes_with_rating")
        .select(
          "*, contents_uom(text), difficulty(text), makes_uom(text), summary(text), title(text), recipes_ingredients(title)"
        )
        .in("id", ids);
      const { data } = await query;
      return data;
    } catch (err) {
      console.log(err);
    }
  };
  const { data: recipeItems } = useQuery(
    ["getRecipeItems", content?.recipes_ids],
    getRecipeItems,
    {
      enabled: Boolean(content?.recipes_ids && content?.recipes_ids.length),
    }
  );
  const { query } = useRouter();
  return (
    <Box width="100%" {...props} id={content?.anchor_id}>
      <TitleCombo
        first={first}
        content={content}
        border={query.slug === "green-friday-2022"}
      />
      <Stack
        space={[0, 3]}
        flexDirection={["column", "column", "row"]}
        width="100%"
      >
        {recipeItems && recipeItems.length
          ? recipeItems.map((blog) => (
              <Fragment key={blog?.id}>
                <BlogCard
                  width={["100%", "100%", "23.33%"]}
                  content={blog}
                  mb={[2, 0]}
                />
              </Fragment>
            ))
          : content?.blog_list?.map((blog) => (
              <Fragment key={blog?.id}>
                <BlogCard
                  width={["100%", "100%", "23.33%"]}
                  {...blog}
                  mb={[2, 0]}
                />
              </Fragment>
            ))}
      </Stack>
    </Box>
  );
};
